const {
  mdiContentCopy,
  mdiDraw,
  mdiCogOutline,
  mdiFileOutline,
  mdiImageOffOutline,
  mdiRestore,
  mdiCheckboxMarkedCircleOutline,
  mdiShieldLockOutline,
  mdiFileExportOutline,
  mdiFileCompare,
  mdiInformationOutline,
  mdiWebClock,
  mdiClose,
  mdiFormTextboxPassword,
  mdiPlusThick,
  mdiCloseThick,
  mdiEmailNewsletter,
  mdiOpenInNew,
  mdiPlusCircleOutline,
  mdiExclamationThick,
  mdiCheckAll,
  mdiPencilCircleOutline,
  mdiCurrencyUsd,
  mdiAccountAlert,
  mdiViewDashboard,
  mdiBadgeAccount,
  mdiAccountSupervisor,
  mdiPodium,
  mdiAbacus,
  mdiHelpBoxMultipleOutline,
  mdiChartArc,
  mdiAccountStar,
  mdiTrophyAward,
  mdiTrophyBroken,
  mdiClockCheckOutline,
  mdiAccountClockOutline,
  mdiAccountBoxMultiple,
  mdiReceiptText,
  mdiTicket,
  mdiDatabaseSearch,
  mdiDatabaseCog,
  mdiPowerStandby,
  mdiDownload,
  mdiRefresh,
  mdiReload,
  mdiArrowLeft,
  mdiFilter,
  mdiMagnify,
  mdiProgressWrench,
  mdiLockReset,
  mdiArrowCollapse,
  mdiArrowExpand,
  mdiMap,
  mdiContentSaveAllOutline,
  mdiMapMarkerRadius,
  mdiChevronUp,
  mdiChevronDown,
  mdiAlertOctagonOutline,
  mdiDatabaseEdit,
  mdiNumeric0Box,
  mdiNumeric1Box,
  mdiNumeric2Box,
  mdiNumeric3Box,
  mdiNumeric4Box,
  mdiNumeric5Box,
  mdiNumeric6Box,
  mdiNumeric7Box,
  mdiNumeric8Box,
  mdiNumeric9Box,
  mdiTicketConfirmation,
  mdiAccountCancelOutline,
  mdiAccountCheckOutline,
  mdiAccountConvertOutline,
  mdiDownloadBoxOutline,
  mdiIpNetworkOutline,
  mdiDatabaseArrowDown,
  mdiEmailFast,
  mdiDatabaseImport,
  mdiKeyChain,
  mdiAccountLockOpen,
  mdiAccountLock,
  mdiDeleteOutline,
  mdiEmailPlusOutline,
  mdiEmailSyncOutline,
  mdiNotebookOutline,
  mdiNotebookPlus,
  mdiNotebookEditOutline,
  mdiSprout,
  mdiThemeLightDark,
  mdiWeatherSunny,
  mdiWhiteBalanceSunny,
  mdiWeatherNight,
  mdiLightningBolt,
  mdiArrowRightThin,
  mdiCloseCircleOutline,
  mdiSwapHorizontal,
  mdiMenu,
  mdiPlayPause,
  mdiEmailArrowRight,
  mdiLinkedin,
  mdiFacebook,
  mdiInstagram,
  mdiYoutube,
  mdiTrashCanOutline,
  mdiPlus,
  mdiMinus,
  mdiEye,
  mdiEyeOff,
  mdiSeal,
  mdiMessageDraw,
  mdiFileDocumentAlert,
  mdiGoogleAssistant,
  mdiEmailCheck,
  mdiEmailRemove
} = require('@mdi/js')

const app = {
  show: mdiEye,
  hide: mdiEyeOff,
  playPause: mdiPlayPause,
  notebookEditOutline: mdiNotebookEditOutline,
  swap: mdiSwapHorizontal,
  faq: mdiHelpBoxMultipleOutline,
  permissions: mdiShieldLockOutline,
  templates: mdiEmailNewsletter,
  dispatchCreate: mdiEmailPlusOutline,
  dispatchEmail: mdiEmailArrowRight,
  export: mdiFileExportOutline,
  calculate: mdiFileCompare,
  info: mdiInformationOutline,
  webClock: mdiWebClock,
  pwd: mdiFormTextboxPassword,
  add: mdiPlusThick,
  close: mdiClose,
  remove: mdiCloseThick,
  openInNew: mdiOpenInNew,
  plus: mdiPlusCircleOutline,
  alert: mdiExclamationThick,
  check: mdiCheckAll,
  undo: mdiRestore,
  select: mdiCheckboxMarkedCircleOutline,
  currency: mdiCurrencyUsd,
  accountAlert: mdiAccountAlert,
  dashboard: mdiViewDashboard,
  user: mdiBadgeAccount,
  admins: mdiAccountSupervisor,
  podium: mdiPodium,
  abacus: mdiAbacus,
  statistics: mdiChartArc,
  prizes: {
    default: mdiTrophyAward,
    invalid: mdiTrophyBroken,
    available: mdiClockCheckOutline,
    validated: mdiAccountStar,
    pending: mdiAccountClockOutline
  },
  account: {
    canceld: mdiAccountCancelOutline,
    active: mdiAccountCheckOutline,
  },
  winners: mdiAccountStar,
  changeAccount: mdiAccountConvertOutline,
  registrations: mdiAccountBoxMultiple,
  invoices: mdiReceiptText,
  tickets: mdiTicket,
  tokens: mdiKeyChain,
  lock: {
    open: mdiAccountLockOpen,
    closed: mdiAccountLock
  },
  dispatches: mdiEmailFast,
  exports: mdiDatabaseArrowDown,
  import: mdiDatabaseImport,
  indexes: mdiDatabaseSearch,
  commands: mdiDatabaseCog,
  logout: mdiPowerStandby,
  download: mdiDownload,
  refresh: mdiRefresh,
  reload: mdiReload,
  left: mdiArrowLeft,
  filter: mdiFilter,
  search: mdiMagnify,
  wrench: mdiProgressWrench,
  messageDraw: mdiMessageDraw,
  filealert: mdiFileDocumentAlert,
  lockReset: mdiLockReset,
  collapse: mdiArrowCollapse,
  expand: mdiArrowExpand,
  map: mdiMap,
  mapMarker: mdiMapMarkerRadius,
  actions: mdiDatabaseEdit,
  zero: mdiNumeric0Box,
  one: mdiNumeric1Box,
  two: mdiNumeric2Box,
  three: mdiNumeric3Box,
  four: mdiNumeric4Box,
  five: mdiNumeric5Box,
  six: mdiNumeric6Box,
  seven: mdiNumeric7Box,
  eight: mdiNumeric8Box,
  nine: mdiNumeric9Box,
  ticketTotal: mdiTicketConfirmation,
  exportGeneral: mdiDownloadBoxOutline,
  ip: mdiIpNetworkOutline,
  save: mdiContentSaveAllOutline,
  fill: mdiDraw,
  file: mdiFileOutline,
  imageOff: mdiImageOffOutline,
  clip: mdiContentCopy,
  mapMarker: mdiMapMarkerRadius,
  updateEmail: mdiEmailSyncOutline,
  regulation: mdiNotebookOutline,
  addRegulation: mdiNotebookPlus,
  edit: mdiPencilCircleOutline,
  sprout: mdiSprout,
  cog: mdiCogOutline,
  themeLightDark: mdiThemeLightDark,
  sun: mdiWeatherSunny,
  whiteBalance: mdiWhiteBalanceSunny,
  night: mdiWeatherNight,
  bolt: mdiLightningBolt,
  rightThin: mdiArrowRightThin,
  closeCircle: mdiCloseCircleOutline,
  menu: mdiMenu,
  linkedin: mdiLinkedin,
  facebook: mdiFacebook,
  instagram: mdiInstagram,
  youtube: mdiYoutube,
  trash: mdiTrashCanOutline,
  plusSimple: mdiPlus,
  minus: mdiMinus,
  winnerDraw: mdiSeal,
  assistant: mdiGoogleAssistant,
  emailsDispatch: mdiEmailCheck,
  notSentEmail: mdiEmailRemove
}

const vuetify = {
  info: mdiInformationOutline,
  sort: mdiChevronUp,
  edit: mdiPencilCircleOutline,
  clear: mdiClose,
  delete: mdiDeleteOutline,
  dropdown: mdiChevronDown,
  subgroup: mdiChevronDown,
  warning: mdiAlertOctagonOutline
}

module.exports = { app, vuetify }
