import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5cf616b6 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _209e5624 = () => interopDefault(import('../pages/agegate.vue' /* webpackChunkName: "pages/agegate" */))
const _7b8e0d80 = () => interopDefault(import('../pages/cupom.vue' /* webpackChunkName: "pages/cupom" */))
const _37535c8c = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _5bd5b794 = () => interopDefault(import('../pages/ganhadores.vue' /* webpackChunkName: "pages/ganhadores" */))
const _cf0c4850 = () => interopDefault(import('../pages/perfil.vue' /* webpackChunkName: "pages/perfil" */))
const _35e833e4 = () => interopDefault(import('../pages/regulamentos.vue' /* webpackChunkName: "pages/regulamentos" */))
const _e0cc7fb8 = () => interopDefault(import('../pages/admin/actions/index.vue' /* webpackChunkName: "pages/admin/actions/index" */))
const _1964dfa6 = () => interopDefault(import('../pages/admin/calculation.vue' /* webpackChunkName: "pages/admin/calculation" */))
const _2fd088d7 = () => interopDefault(import('../pages/admin/commands/index.vue' /* webpackChunkName: "pages/admin/commands/index" */))
const _952f5700 = () => interopDefault(import('../pages/admin/cpf-block/index.vue' /* webpackChunkName: "pages/admin/cpf-block/index" */))
const _02002178 = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _34f373f7 = () => interopDefault(import('../pages/admin/dispatches/index.vue' /* webpackChunkName: "pages/admin/dispatches/index" */))
const _6cfcb0a2 = () => interopDefault(import('../pages/admin/email-templates/index.vue' /* webpackChunkName: "pages/admin/email-templates/index" */))
const _30b06fe6 = () => interopDefault(import('../pages/admin/exports/index.vue' /* webpackChunkName: "pages/admin/exports/index" */))
const _ff040606 = () => interopDefault(import('../pages/admin/faq/index.vue' /* webpackChunkName: "pages/admin/faq/index" */))
const _83484f32 = () => interopDefault(import('../pages/admin/indexes/index.vue' /* webpackChunkName: "pages/admin/indexes/index" */))
const _23366bc6 = () => interopDefault(import('../pages/admin/invalid-prizes/index.vue' /* webpackChunkName: "pages/admin/invalid-prizes/index" */))
const _192f7896 = () => interopDefault(import('../pages/admin/invoices/index.vue' /* webpackChunkName: "pages/admin/invoices/index" */))
const _2d8db2da = () => interopDefault(import('../pages/admin/ips/index.vue' /* webpackChunkName: "pages/admin/ips/index" */))
const _be302e2a = () => interopDefault(import('../pages/admin/permissions/index.vue' /* webpackChunkName: "pages/admin/permissions/index" */))
const _3aff02a0 = () => interopDefault(import('../pages/admin/prizes/index.vue' /* webpackChunkName: "pages/admin/prizes/index" */))
const _411b667e = () => interopDefault(import('../pages/admin/registrations/index.vue' /* webpackChunkName: "pages/admin/registrations/index" */))
const _77ee1c30 = () => interopDefault(import('../pages/admin/regulations/index.vue' /* webpackChunkName: "pages/admin/regulations/index" */))
const _789ff56f = () => interopDefault(import('../pages/admin/statistics.vue' /* webpackChunkName: "pages/admin/statistics" */))
const _7ed5918e = () => interopDefault(import('../pages/admin/tickets/index.vue' /* webpackChunkName: "pages/admin/tickets/index" */))
const _442dea89 = () => interopDefault(import('../pages/admin/tickets-check/index.vue' /* webpackChunkName: "pages/admin/tickets-check/index" */))
const _591edb2e = () => interopDefault(import('../pages/admin/tokens/index.vue' /* webpackChunkName: "pages/admin/tokens/index" */))
const _4ebdfa45 = () => interopDefault(import('../pages/admin/total-tickets/index.vue' /* webpackChunkName: "pages/admin/total-tickets/index" */))
const _6291d322 = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _3912630a = () => interopDefault(import('../pages/admin/winners/index.vue' /* webpackChunkName: "pages/admin/winners/index" */))
const _189e9d28 = () => interopDefault(import('../pages/admin/winners-draw/index.vue' /* webpackChunkName: "pages/admin/winners-draw/index" */))
const _7abd6a0e = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _e505bd64 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _1a506664 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _1c859b64 = () => interopDefault(import('../pages/admin/auth/forgot.vue' /* webpackChunkName: "pages/admin/auth/forgot" */))
const _4f43510e = () => interopDefault(import('../pages/admin/auth/login.vue' /* webpackChunkName: "pages/admin/auth/login" */))
const _1b637618 = () => interopDefault(import('../pages/admin/auth/update.vue' /* webpackChunkName: "pages/admin/auth/update" */))
const _dda154b2 = () => interopDefault(import('../pages/admin/dispatches/create.vue' /* webpackChunkName: "pages/admin/dispatches/create" */))
const _745500b6 = () => interopDefault(import('../pages/admin/dispatches/dispatch-list.vue' /* webpackChunkName: "pages/admin/dispatches/dispatch-list" */))
const _e330ce22 = () => interopDefault(import('../pages/admin/email-templates/create.vue' /* webpackChunkName: "pages/admin/email-templates/create" */))
const _9214253e = () => interopDefault(import('../pages/admin/faq/create.vue' /* webpackChunkName: "pages/admin/faq/create" */))
const _a4ec96e4 = () => interopDefault(import('../pages/admin/regulations/create.vue' /* webpackChunkName: "pages/admin/regulations/create" */))
const _7b53f516 = () => interopDefault(import('../pages/admin/tokens/create.vue' /* webpackChunkName: "pages/admin/tokens/create" */))
const _2fe0022f = () => interopDefault(import('../pages/admin/users/create.vue' /* webpackChunkName: "pages/admin/users/create" */))
const _abcc725c = () => interopDefault(import('../pages/admin/winners-draw/create.vue' /* webpackChunkName: "pages/admin/winners-draw/create" */))
const _4d29c314 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _39c82b25 = () => interopDefault(import('../pages/admin/auth/reset/_token.vue' /* webpackChunkName: "pages/admin/auth/reset/_token" */))
const _767a70e8 = () => interopDefault(import('../pages/admin/email-templates/edit/_id.vue' /* webpackChunkName: "pages/admin/email-templates/edit/_id" */))
const _96751e4c = () => interopDefault(import('../pages/admin/faq/edit/_id.vue' /* webpackChunkName: "pages/admin/faq/edit/_id" */))
const _558420c7 = () => interopDefault(import('../pages/admin/regulations/edit/_id.vue' /* webpackChunkName: "pages/admin/regulations/edit/_id" */))
const _c904ebb0 = () => interopDefault(import('../pages/admin/users/edit/_id.vue' /* webpackChunkName: "pages/admin/users/edit/_id" */))
const _6e58b20b = () => interopDefault(import('../pages/admin/winners-draw/edit/_id.vue' /* webpackChunkName: "pages/admin/winners-draw/edit/_id" */))
const _62217b8c = () => interopDefault(import('../pages/admin/actions/_id.vue' /* webpackChunkName: "pages/admin/actions/_id" */))
const _4fec081f = () => interopDefault(import('../pages/admin/dispatches/_id.vue' /* webpackChunkName: "pages/admin/dispatches/_id" */))
const _46d8a7ae = () => interopDefault(import('../pages/admin/invalid-prizes/_id.vue' /* webpackChunkName: "pages/admin/invalid-prizes/_id" */))
const _182daf5d = () => interopDefault(import('../pages/admin/invoices/_id.vue' /* webpackChunkName: "pages/admin/invoices/_id" */))
const _3ec26872 = () => interopDefault(import('../pages/admin/ips/_ip.vue' /* webpackChunkName: "pages/admin/ips/_ip" */))
const _4f0325d0 = () => interopDefault(import('../pages/admin/prizes/_id.vue' /* webpackChunkName: "pages/admin/prizes/_id" */))
const _1602e92e = () => interopDefault(import('../pages/admin/registrations/_id.vue' /* webpackChunkName: "pages/admin/registrations/_id" */))
const _6dab9dcd = () => interopDefault(import('../pages/admin/validation/_prize.vue' /* webpackChunkName: "pages/admin/validation/_prize" */))
const _262f92e5 = () => interopDefault(import('../pages/auth/reset/_token.vue' /* webpackChunkName: "pages/auth/reset/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _5cf616b6,
    name: "admin"
  }, {
    path: "/agegate",
    component: _209e5624,
    name: "agegate"
  }, {
    path: "/cupom",
    component: _7b8e0d80,
    name: "cupom"
  }, {
    path: "/faq",
    component: _37535c8c,
    name: "faq"
  }, {
    path: "/ganhadores",
    component: _5bd5b794,
    name: "ganhadores"
  }, {
    path: "/perfil",
    component: _cf0c4850,
    name: "perfil"
  }, {
    path: "/regulamentos",
    component: _35e833e4,
    name: "regulamentos"
  }, {
    path: "/admin/actions",
    component: _e0cc7fb8,
    name: "admin-actions"
  }, {
    path: "/admin/calculation",
    component: _1964dfa6,
    name: "admin-calculation"
  }, {
    path: "/admin/commands",
    component: _2fd088d7,
    name: "admin-commands"
  }, {
    path: "/admin/cpf-block",
    component: _952f5700,
    name: "admin-cpf-block"
  }, {
    path: "/admin/dashboard",
    component: _02002178,
    name: "admin-dashboard"
  }, {
    path: "/admin/dispatches",
    component: _34f373f7,
    name: "admin-dispatches"
  }, {
    path: "/admin/email-templates",
    component: _6cfcb0a2,
    name: "admin-email-templates"
  }, {
    path: "/admin/exports",
    component: _30b06fe6,
    name: "admin-exports"
  }, {
    path: "/admin/faq",
    component: _ff040606,
    name: "admin-faq"
  }, {
    path: "/admin/indexes",
    component: _83484f32,
    name: "admin-indexes"
  }, {
    path: "/admin/invalid-prizes",
    component: _23366bc6,
    name: "admin-invalid-prizes"
  }, {
    path: "/admin/invoices",
    component: _192f7896,
    name: "admin-invoices"
  }, {
    path: "/admin/ips",
    component: _2d8db2da,
    name: "admin-ips"
  }, {
    path: "/admin/permissions",
    component: _be302e2a,
    name: "admin-permissions"
  }, {
    path: "/admin/prizes",
    component: _3aff02a0,
    name: "admin-prizes"
  }, {
    path: "/admin/registrations",
    component: _411b667e,
    name: "admin-registrations"
  }, {
    path: "/admin/regulations",
    component: _77ee1c30,
    name: "admin-regulations"
  }, {
    path: "/admin/statistics",
    component: _789ff56f,
    name: "admin-statistics"
  }, {
    path: "/admin/tickets",
    component: _7ed5918e,
    name: "admin-tickets"
  }, {
    path: "/admin/tickets-check",
    component: _442dea89,
    name: "admin-tickets-check"
  }, {
    path: "/admin/tokens",
    component: _591edb2e,
    name: "admin-tokens"
  }, {
    path: "/admin/total-tickets",
    component: _4ebdfa45,
    name: "admin-total-tickets"
  }, {
    path: "/admin/users",
    component: _6291d322,
    name: "admin-users"
  }, {
    path: "/admin/winners",
    component: _3912630a,
    name: "admin-winners"
  }, {
    path: "/admin/winners-draw",
    component: _189e9d28,
    name: "admin-winners-draw"
  }, {
    path: "/forgot",
    component: _7abd6a0e,
    name: "auth-forgot"
  }, {
    path: "/login",
    component: _e505bd64,
    name: "auth-login"
  }, {
    path: "/register",
    component: _1a506664,
    name: "auth-register"
  }, {
    path: "/admin/forgot",
    component: _1c859b64,
    name: "admin-auth-forgot"
  }, {
    path: "/admin/login",
    component: _4f43510e,
    name: "admin-auth-login"
  }, {
    path: "/admin/update",
    component: _1b637618,
    name: "admin-auth-update"
  }, {
    path: "/admin/dispatches/create",
    component: _dda154b2,
    name: "admin-dispatches-create"
  }, {
    path: "/admin/dispatches/dispatch-list",
    component: _745500b6,
    name: "admin-dispatches-dispatch-list"
  }, {
    path: "/admin/email-templates/create",
    component: _e330ce22,
    name: "admin-email-templates-create"
  }, {
    path: "/admin/faq/create",
    component: _9214253e,
    name: "admin-faq-create"
  }, {
    path: "/admin/regulations/create",
    component: _a4ec96e4,
    name: "admin-regulations-create"
  }, {
    path: "/admin/tokens/create",
    component: _7b53f516,
    name: "admin-tokens-create"
  }, {
    path: "/admin/users/create",
    component: _2fe0022f,
    name: "admin-users-create"
  }, {
    path: "/admin/winners-draw/create",
    component: _abcc725c,
    name: "admin-winners-draw-create"
  }, {
    path: "/",
    component: _4d29c314,
    name: "index"
  }, {
    path: "/admin/reset/:token?",
    component: _39c82b25,
    name: "admin-auth-reset-token"
  }, {
    path: "/admin/email-templates/edit/:id",
    component: _767a70e8,
    name: "admin-email-templates-edit-id"
  }, {
    path: "/admin/faq/edit/:id?",
    component: _96751e4c,
    name: "admin-faq-edit-id"
  }, {
    path: "/admin/regulations/edit/:id?",
    component: _558420c7,
    name: "admin-regulations-edit-id"
  }, {
    path: "/admin/users/edit/:id?",
    component: _c904ebb0,
    name: "admin-users-edit-id"
  }, {
    path: "/admin/winners-draw/edit/:id",
    component: _6e58b20b,
    name: "admin-winners-draw-edit-id"
  }, {
    path: "/admin/actions/:id",
    component: _62217b8c,
    name: "admin-actions-id"
  }, {
    path: "/admin/dispatches/:id",
    component: _4fec081f,
    name: "admin-dispatches-id"
  }, {
    path: "/admin/invalid-prizes/:id?",
    component: _46d8a7ae,
    name: "admin-invalid-prizes-id"
  }, {
    path: "/admin/invoices/:id",
    component: _182daf5d,
    name: "admin-invoices-id"
  }, {
    path: "/admin/ips/:ip",
    component: _3ec26872,
    name: "admin-ips-ip"
  }, {
    path: "/admin/prizes/:id",
    component: _4f0325d0,
    name: "admin-prizes-id"
  }, {
    path: "/admin/registrations/:id",
    component: _1602e92e,
    name: "admin-registrations-id"
  }, {
    path: "/admin/validation/:prize?",
    component: _6dab9dcd,
    name: "admin-validation-prize"
  }, {
    path: "/reset/:token?",
    component: _262f92e5,
    name: "auth-reset-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
